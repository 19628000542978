.booking-form {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 40px;
  max-width: 600px;
  margin: 20px auto;
  margin-top: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.booking-form-submitted {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  max-width: 600px;
  margin: 20px auto;
  margin-top: 50px;
  margin-bottom: 377px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
