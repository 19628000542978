.food-item-card {
  width: -moz-fit-content; /* Add this line to support Firefox and Firefox for Android */
  width: fit-content;
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.food-card-text-content {
  display: flex;
  flex-direction: column;
  padding: 0px 25px;

  background-color: var(--secondary-color-4);
}
.order-delivery {
  font-weight: bold;
}
.order-delivery-section {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: start;
  padding: 10px 0px;
}
.food-card-description {
  height: 105px;
}
