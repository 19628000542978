.restaurant-specials {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 80px 20px;
  max-width: 1200px;
  margin: auto;
}
.specials-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 0px;
}
.specials-header-text {
  font-size: 40px;
  margin-top: 0px;
}

.menu-button {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
}

.online-menu-btn {
  margin-top: 0px;
}
.specials-cards {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  padding-top: 20px;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .specials-header {
    flex-direction: column;
    align-items: center;
  }
}
