.about-us-main-container {
  padding: 150px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

.about-us-text-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 30%;
}

.about-us-title {
  font-size: 2.5rem;
  font-weight: 700;
}
.about-us-location {
  font-size: 1.5rem;
  font-weight: 500;
}
.about-us-images {
  position: relative;
  width: 300px;
  height: 300px;
  margin-left: 200px;
}

.about-us-images img:first-child {
  top: 0;
  left: 0;
}

.about-us-images img:last-child {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
  .about-us-main-container {
    padding: 100px 20px;
  }
  .about-us-text-content {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .about-us-images {
    width: 200px;
    height: 200px;
    margin-left: 0;
  }
}
